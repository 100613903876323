import { useTranslation } from 'react-i18next';
import { Stage, Navigator } from '../../layout/stage/Stage';
import Card, { CardSection, InlineCards } from '../../elements/card/Card';
import LineChart from '../../sections/widgets/linechart/LineChart';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import DateRange from '../../elements/daterange/DateRange';
import {
  DailyRevenueResponse,
  SimpleOrderResponse,
  UnfulfilledOrdersResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import { DropdownOption } from '../../elements/selectors/Selectors';
import { useUser } from '../../contexts/auth/User';
import { useNavigate } from 'react-router-dom';
import { Has, useAuthorization } from '../../contexts/auth/Authorization';
import ProductTour from '../../sections/widgets/producttour/ProductTour';
import SalesOverview from '../../sections/widgets/salesoverview/SalesOverview';
import OrderList from '../../features/orderlist/OrderList';
import OrderStateOverview from '../../sections/widgets/orderstateoverview/OrderStateOverview';
import useDateTools from '../../hooks/useDateTools';
import DailyBestSellers from '../../sections/widgets/dailybestsellers/DailyBestSellers';
import Bestsellers from '../../sections/widgets/bestsellers/Bestsellers';
import RecurringCustomers from '../../sections/widgets/recurringcustomers/RecurringCustomers';
import DropdownMenu from '../../elements/dropdownmenu/DropdownMenu';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { authorizations } = useAuthorization();
  const link = useNavigate();
  const api = usePetCloudApi();
  const dashboardsApi = api.dashboardsApi();
  const manufacturersApi = api.manufacturersApi();
  const ordersApi = api.ordersApi();
  const errorHandler = useErrorHandler();
  const { dateToCSharpDateTimeOffset, getDateOnlyFromDate } = useDateTools();

  const getDefaultDateRange = () => {
    const date = new Date();
    const today = getDateOnlyFromDate(date) ?? 'error';
    const beginningOfMonth =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-01';
    return [beginningOfMonth, today];
  };
  const [selectedDateRange, setSelectedDateRange] = useState(
    getDefaultDateRange()
  );
  const [revenueData, setRevenueData] = useState<DailyRevenueResponse[] | null>(
    null
  );
  const [manufacturers, setManufacturers] = useState<DropdownOption[] | null>(
    null
  );
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<DropdownOption | null>(null);
  const [orders, setOrders] = useState<SimpleOrderResponse[] | null>(null);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState<
    UnfulfilledOrdersResponse[] | null
  >(null);

  useEffect(() => {
    if (!user?.manufacturerId) {
      getManufacturers();
    }
    getUnfulfilledOrders();
  }, []);

  const getManufacturerId = () => {
    const userManufacturerId = user?.manufacturerId;
    if (userManufacturerId) {
      return userManufacturerId;
    }
    const selectedManufacturerId = selectedManufacturer?.id;
    if (selectedManufacturerId) {
      return selectedManufacturerId !== 'all'
        ? selectedManufacturerId
        : undefined;
    }
  };

  useEffect(() => {
    const mId = getManufacturerId();
    getRevenueData(selectedDateRange[0], selectedDateRange[1], mId);
    getOrders(
      dateToCSharpDateTimeOffset(new Date(selectedDateRange[0]), true),
      dateToCSharpDateTimeOffset(new Date(selectedDateRange[1])),
      mId
    );
  }, [selectedManufacturer, selectedDateRange]);

  const getUnfulfilledOrders = () => {
    dashboardsApi
      .dashboardsGetUnfulfilledOrders()
      .then((response) => {
        console.log(response);
        setUnfulfilledOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getManufacturers = () => {
    manufacturersApi
      .manufacturersGetSimpleManufacturers()
      .then((response) => {
        console.log(response);
        const options = response.data.map((manufacturer) => {
          return {
            id: manufacturer.id,
            name: manufacturer.companyName,
          };
        });
        if (!user?.manufacturerId) {
          options.unshift({
            id: 'all',
            name: t('view.dashboard.allManufacturers'),
          });
        }
        setManufacturers(options);
        setSelectedManufacturer(options[0]);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getRevenueData = (
    from: string,
    to: string,
    manufacturerId: string | undefined
  ) => {
    dashboardsApi
      .dashboardsGetDailyRevenue(manufacturerId, from, to)
      .then((response) => {
        console.log(response);
        const result = injectMissingDays(
          response.data,
          new Date(from),
          new Date(to)
        );
        setRevenueData(result);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getOrders = (from?: string, to?: string, manufacturerId?: string) => {
    setOrders(null);
    ordersApi
      .ordersGetSimpleOrdersList(undefined, from, to)
      .then((response) => {
        console.log(response);
        if (manufacturerId) {
          setOrders(
            response.data.filter(
              (order) => order.manufacturer.id === manufacturerId
            )
          );
        } else {
          setOrders(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (authorizations) {
    return (
      <Has authorizations={['dashboard:list']}>
        {revenueData && orders && unfulfilledOrders ? (
          <Stage>
            <Navigator title={t('view.dashboard.title')}>
              {manufacturers && selectedManufacturer ? (
                <div style={{ marginRight: 10 }}>
                  <DropdownMenu
                    optionObjects={manufacturers}
                    selected={selectedManufacturer.name}
                    onSelect={(value, id) => {
                      if (id) {
                        const selected = manufacturers.find((m) => m.id === id);
                        if (selected) {
                          setSelectedManufacturer(selected);
                        }
                      }
                    }}
                  />
                </div>
              ) : null}
              <DateRange
                selectedDateRange={selectedDateRange}
                update={(range) => {
                  setSelectedDateRange(range);
                }}
              />
            </Navigator>
            <ProductTour
              title={t('view.dashboard.productTour.title')}
              text={t('view.dashboard.productTour.text')}
              settingName={'productTour_welcome'}
              infoItems={[
                {
                  title: t('knowledgebase.product.tutorials.new.title'),
                  text: t('knowledgebase.product.tutorials.new.text'),
                  videoUri: t('knowledgebase.product.tutorials.new.video'),
                },
                {
                  title: t('knowledgebase.settings.team.addMember.title'),
                  text: t('knowledgebase.settings.team.addMember.text'),
                  videoUri: t('knowledgebase.settings.team.addMember.media'),
                },
                {
                  title: t('knowledgebase.product.tutorials.ready.title'),
                  text: t('knowledgebase.product.tutorials.ready.text'),
                  videoUri: t('knowledgebase.product.tutorials.ready.video'),
                },
              ]}
            />
            <InlineCards>
              <Card
                bigScreenWidth="33.33%"
                title={t('view.dashboard.salesOverview')}
              >
                <CardSection>
                  <SalesOverview
                    mode={'revenue'}
                    revenueData={revenueData}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="33.33%"
                title={t('view.dashboard.orderCount')}
              >
                <CardSection>
                  <SalesOverview
                    mode={'orders'}
                    revenueData={revenueData}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="33.33%"
                title={t('view.dashboard.orderOverview')}
              >
                <CardSection>
                  <OrderStateOverview
                    orders={orders}
                    unfulfilledOrders={unfulfilledOrders}
                    height={240}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth="50%"
                title={t('view.dashboard.dailyRevenues')}
              >
                <CardSection>
                  <LineChart
                    data={revenueData}
                    dataKey={'totalRevenue'}
                    xAxis={{ dataKey: 'date' }}
                    height={220}
                  />
                </CardSection>
              </Card>
              <Card
                bigScreenWidth="50%"
                title={t('view.dashboard.dailyOrders')}
              >
                <CardSection>
                  <LineChart
                    data={revenueData}
                    dataKey={'totalSuccessOrders'}
                    xAxis={{ dataKey: 'date' }}
                    height={220}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth={'100%'}
                title={t('view.dashboard.bestsellers')}
              >
                <CardSection>
                  <Bestsellers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth={'100%'}
                title={t('view.dashboard.dailyBestsellers')}
              >
                <CardSection>
                  <DailyBestSellers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth={'100%'}
                title={t('view.dashboard.recurringCustomers')}
              >
                <CardSection>
                  <RecurringCustomers
                    dateRangeFrom={selectedDateRange[0]}
                    dateRangeTo={selectedDateRange[1]}
                    selectedManufacturerId={selectedManufacturer?.id}
                  />
                </CardSection>
              </Card>
            </InlineCards>
            <InlineCards>
              <Card
                bigScreenWidth="100%"
                title={t('view.dashboard.recentOrders')}
                cta={t('view.dashboard.allOrders')}
                noMargin
                action={() => link('/orders')}
              >
                <CardSection>
                  <OrderList
                    ordersArray={orders}
                    unfulfilledOrdersArray={unfulfilledOrders}
                    height={600}
                  />
                </CardSection>
              </Card>
            </InlineCards>
          </Stage>
        ) : (
          <LoadingContainer />
        )}
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Dashboard;

export const injectMissingDays = (
  data: DailyRevenueResponse[],
  startDate: Date,
  endDate: Date
) => {
  console.log(startDate);
  console.log(endDate);

  const allDates = getAllDatesBetween(startDate, endDate);
  return allDates.map((date) => {
    const dailyRevenue = data.find((d) => d.date === date);
    if (dailyRevenue) {
      return dailyRevenue;
    } else {
      return {
        manufacturerId: null,
        date: date,
        totalRevenueNet: 0,
        totalRevenue: 0,
        totalSuccessOrders: 0,
        totalFailedOrders: 0,
      };
    }
  });
};

export const getAllDatesBetween = (startDate: Date, endDate: Date) => {
  const result: string[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    result.push(
      `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`
    );
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  console.log(result);
  return result;
};
