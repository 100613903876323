import './propertybrowser.css';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductGroupMappingResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
} from '../../api/petcloudapi/api';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { Check } from '../../elements/selectors/Selectors';
import SearchablePropertyOptionResponse from '../../types/SearchablePropertyOptionResponse';
import PropertiesSearch from './propertiessearch/PropertiesSearch';
import { ReactComponent as IconProhibit } from '../../../assets/icon/prohibit.svg';
import Badge from '../../elements/badge/Badge';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import NewUserPropertyOption from './newuserpropertyoption/NewUserPropertyOption';
import { useUser } from '../../contexts/auth/User';
import NewPropertyOption from '../../sections/admin/productproperties/propertygroupedit/newpropertyoption/NewPropertyOption';
import _ from 'lodash';
import { ReactComponent as IconTrash } from '../../../assets/icon/trash.svg';
import { ReactComponent as IconReload } from '../../../assets/icon/reload.svg';
import ToggleSwitch from '../../elements/toggleswitch/ToggleSwitch';

interface PropertyBrowserProps {
  availablePropertyGroups: PropertyGroupResponse[];
  refreshAvailablePropertyGroups?: () => void;
  selectedPropertyOptions?: PropertyOptionResponse[];
  updateSelectedPropertyOptions?: (options: PropertyOptionResponse[]) => void;
  selectedPropertyGroups?: PropertyGroupResponse[];
  updateSelectedPropertyGroups?: (groups: PropertyGroupResponse[]) => void;
  variantOptionsOnly?: boolean;
  blockedPropertyOptions?: PropertyOptionResponse[] | null;
  withSearch?: boolean;
  optionsDisabled?: boolean;
  optionsDisabledMessage?: string;
  productGroupMappings?: ProductGroupMappingResponse[];
  // makes adding options for certain property groups required or optional while validating either the input for creating user options or limiting the selectable options.
  selectedAnimalSpeciesIds?: string[];
  // required as param for creating new user options
  userInputCreatesVariantOption?: boolean;
  // sets isVariantOption = true when creating new user options in NewUserPropertyOption.tsx
  validationOptional?: boolean;
  // hides the required badge behind required property groups
  isVariantOption?: boolean;
  maxHeight?: string | number;
}

const PropertyBrowser: React.FC<PropertyBrowserProps> = ({
  availablePropertyGroups,
  refreshAvailablePropertyGroups,
  selectedPropertyOptions,
  updateSelectedPropertyOptions,
  selectedPropertyGroups,
  updateSelectedPropertyGroups,
  variantOptionsOnly,
  blockedPropertyOptions,
  withSearch,
  optionsDisabled,
  productGroupMappings,
  selectedAnimalSpeciesIds,
  userInputCreatesVariantOption,
  validationOptional,
  isVariantOption,
  maxHeight,
}) => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'components.propertyBrowser',
  });
  const { user } = useUser();
  const [availableProperties, setAvailableProperties] = useState(
    _.sortBy(availablePropertyGroups, 'position')
  );
  const [availableProductGroupMappings, setAvailableProductGroupMappings] =
    useState<ProductGroupMappingResponse[] | null>(
      productGroupMappings ?? null
    );
  const [browsedGroupId, setBrowsedGroupId] = useState<string | null>(null);
  const [searchableProperties, setSearchableProperties] = useState<
    SearchablePropertyOptionResponse[] | null | undefined
  >(null);
  const [newOptionPopup, setNewOptionPopup] = useState(false);
  const [sortBySelect, setSortBySelect] = useState(false);

  useEffect(() => {
    constructSearchableProperties();
  }, []);

  const constructSearchableProperties = () => {
    if (withSearch) {
      const searchable: SearchablePropertyOptionResponse[] = [];
      availableProperties.forEach((group) => {
        group.options.forEach((option) => {
          if (isOptionAllowedToShow(option)) {
            searchable.push({
              ...option,
              groupName: group.name,
            });
          }
        });
      });
      setSearchableProperties(searchable);
    }
  };

  const isOptionAllowedToShow = (option: PropertyOptionResponse) => {
    const mapping = availableProductGroupMappings?.find(
      (m) => m.propertyGroupId === option.propertyGroupId
    );
    const isValidInMapping = mapping?.propertyOptions?.find(
      (o) => o.id === option.id
    );
    if (!variantOptionsOnly || (variantOptionsOnly && option.isVariantOption)) {
      return !!(!mapping || isValidInMapping);
    } else {
      return false;
    }
  };

  const isContainerSelected = (id: string) => {
    return browsedGroupId === id;
  };

  const countSelectedContainerValues = (propertyGroupId: string) => {
    const propertyGroup = availableProperties.find(
      (group) => group.id === propertyGroupId
    );
    if (propertyGroup && selectedPropertyOptions) {
      let counter = 0;
      propertyGroup.options.forEach((option) => {
        if (selectedPropertyOptions.find((o) => o.id === option.id)) {
          counter += 1;
        }
      });
      return counter;
    } else {
      return 0;
    }
  };

  const toggleAllPropertiesOfGroup = () => {};

  const toggleProductProperty = (propertyOption: PropertyOptionResponse) => {
    if (selectedPropertyOptions && updateSelectedPropertyOptions) {
      const i = selectedPropertyOptions.findIndex(
        (option) => option.id === propertyOption.id
      );
      if (i === -1) {
        updateSelectedPropertyOptions([
          ...selectedPropertyOptions,
          propertyOption,
        ]);
      } else {
        const update = selectedPropertyOptions;
        update.splice(i, 1);
        updateSelectedPropertyOptions([...update]);
      }
    }
  };

  const togglePropertyGroup = (group: PropertyGroupResponse) => {
    if (selectedPropertyGroups && updateSelectedPropertyGroups) {
      const i = selectedPropertyGroups?.findIndex((g) => g.id === group.id);
      if (i === -1) {
        updateSelectedPropertyGroups([...selectedPropertyGroups, group]);
      } else {
        const update = selectedPropertyGroups;
        update.splice(i, 1);
        updateSelectedPropertyGroups([...update]);
      }
    }
  };

  const deselectAllOptionsOfOneGroup = (propertyGroupId: string) => {
    if (selectedPropertyOptions && updateSelectedPropertyOptions) {
      const update = selectedPropertyOptions.filter(
        (option) => option.propertyGroupId !== propertyGroupId
      );
      updateSelectedPropertyOptions(update);
    }
  };

  const hasVariantOptions = (group: PropertyGroupResponse) => {
    let result = false;
    group.options.forEach((option) => {
      if (option.isVariantOption) {
        result = true;
      }
    });
    return result;
  };

  const renderContainer = (
    group: PropertyGroupResponse,
    i: number,
    mapping: ProductGroupMappingResponse | undefined,
    selectedValueCount: number
  ) => {
    const lang = i18n.language as TranslatedStringIndex;

    return (
      <div
        key={i}
        className="propertyBrowser-container"
        onClick={() => setBrowsedGroupId(group.id)}
        style={{
          backgroundColor: isContainerSelected(group.id)
            ? 'var(--color-background)'
            : 'transparent',
        }}
      >
        <div className="propertyBrowser-container-title">
          {selectedPropertyGroups && updateSelectedPropertyGroups ? (
            <div className="propertyBrowser-container-title-selector">
              <Check
                checked={
                  !!selectedPropertyGroups.find((g) => g.id === group.id)
                }
                update={() => togglePropertyGroup(group)}
              />
            </div>
          ) : null}
          {group.name[lang]}
          {group.productUnit ? (
            <span>({group.productUnit.name[lang]})</span>
          ) : null}
          {!validationOptional && mapping && !mapping.isOptional ? (
            <div className={'propertyBrowser-container-badge'}>
              <Badge title={t('required')} color={'var(--color-danger)'} />
            </div>
          ) : null}
        </div>
        {selectedValueCount > 0 ? (
          <>
            <div className={'propertyBrowser-container-badge'}>
              <Badge
                title={`${selectedValueCount} ${t('values_selected')}`}
                color={'var(--color-blue)'}
              />
            </div>
            <Button
              type={'icon'}
              width={'tiny'}
              action={() => deselectAllOptionsOfOneGroup(group.id)}
              look={'secondary'}
              margin={'left'}
            >
              <IconTrash
                className={'button-icon button-icon-tiny button-icon-danger'}
              />
            </Button>
          </>
        ) : null}
      </div>
    );
  };

  const selectPropertyGroup = (group: PropertyGroupResponse) => {
    if (selectedPropertyGroups && updateSelectedPropertyGroups) {
      togglePropertyGroup(group);
    }
    setBrowsedGroupId(group.id);
  };

  const renderAllContainers = () => {
    const required: (Element | null)[] = [];
    const notRequired: (Element | null)[] = [];

    availableProperties.forEach((group, i) => {
      const mapping = availableProductGroupMappings?.find(
        (m) => m.propertyGroupId === group.id
      );
      const selectedValueCount = countSelectedContainerValues(group.id);

      if (variantOptionsOnly === true && !hasVariantOptions(group)) return null;

      const pushOrUnshiftContainer = (collection: any) => {
        const action =
          sortBySelect && selectedValueCount > 0 ? 'unshift' : 'push';

        collection[action](
          renderContainer(group, i, mapping, selectedValueCount)
        );
      };

      if (mapping && !mapping.isOptional) {
        pushOrUnshiftContainer(required);
      } else {
        pushOrUnshiftContainer(notRequired);
      }
    });

    return required.concat(notRequired);
  };

  const renderProperties = () => {
    const properties: ReactNode[] = [];
    browsedGroupOptions?.forEach((option, i) => {
      const isSelected = !!selectedPropertyOptions?.find(
        (o) => o.id === option.id
      );
      const isBlocked = !!blockedPropertyOptions?.find(
        (o) => o.id === option.id
      );

      if (sortBySelect && isSelected) {
        properties.unshift(renderProperty(option, i, isSelected, isBlocked));
      } else {
        properties.push(renderProperty(option, i, isSelected, isBlocked));
      }
    });
    return properties;
  };

  const renderProperty = (
    option: PropertyOptionResponse,
    i: number,
    isSelected: boolean,
    isBlocked: boolean
  ) => {
    return (
      <div key={i} className="propertyBrowser-value">
        <div className="propertyBrowser-value-selector">
          <Check
            checked={isSelected ? true : isBlocked}
            update={() => toggleProductProperty(option)}
            disabled={isBlocked}
          />
        </div>
        <div className="propertyBrowser-value-title">
          {option.name[i18n.language as TranslatedStringIndex]}
        </div>
      </div>
    );
  };

  const postNewOptionSubmission = (
    option: PropertyOptionResponse,
    productGroupMapping: ProductGroupMappingResponse | null | undefined
  ) => {
    const updatedProperties = [...availableProperties];
    const groupIndex = updatedProperties.findIndex(
      (g) => g.id === option.propertyGroupId
    );
    const alreadyExists = updatedProperties[groupIndex].options.some(
      (x) => x.id === option.id
    );

    if (groupIndex !== -1 && !alreadyExists) {
      updatedProperties[groupIndex].options.push(option);
      setAvailableProperties(updatedProperties);
    }

    if (availableProductGroupMappings) {
      const updatedMappings = [...availableProductGroupMappings];
      const mappingIndex = updatedMappings.findIndex(
        (m) => m.id === productGroupMapping?.id
      );
      if (mappingIndex !== -1 && !alreadyExists) {
        updatedMappings[mappingIndex].propertyOptions?.push(option);
        setAvailableProductGroupMappings(updatedMappings);
      }
    }
    toggleProductProperty(option);
    setNewOptionPopup(false);
  };

  const renderValueActions = (
    productGroupMapping: ProductGroupMappingResponse | null | undefined
  ) => {
    if (productGroupMapping) {
      if (productGroupMapping.isUserInputAllowed) {
        return (
          <>
            <div className={'propertyBrowser-values-button'}>
              <Button
                cta={t('addNewUserOptionButton')}
                look={'secondary'}
                width={'full'}
                action={() => setNewOptionPopup(true)}
              />
            </div>
            <Popup
              toggled={newOptionPopup}
              width={'30%'}
              close={() => setNewOptionPopup(false)}
            >
              <div className={'popup-title'}>
                {t('newPropertyOption.title')}
              </div>
              <NewUserPropertyOption
                selectedAnimalSpeciesIds={selectedAnimalSpeciesIds}
                productGroupMapping={productGroupMapping}
                postSubmit={(option) =>
                  postNewOptionSubmission(option, productGroupMapping)
                }
                isVariantOption={userInputCreatesVariantOption}
                isLanguageNeutral={
                  productGroupMapping.propertyGroup?.isLanguageNeutral
                }
              />
            </Popup>
          </>
        );
      } else {
        return (
          <div className={'propertyBrowser-values-info'}>
            {t('hasOptionsFilteredByMapping')}
          </div>
        );
      }
    } else if (user?.isProductOwner) {
      const browsedGroup = availablePropertyGroups.find(
        (g) => g.id === browsedGroupId
      );
      if (browsedGroup) {
        return (
          <>
            <div className={'propertyBrowser-values-button'}>
              <Button
                cta={t('addNewOptionButton')}
                look={'secondary'}
                width={'full'}
                action={() => setNewOptionPopup(true)}
              />
            </div>
            <Popup
              toggled={newOptionPopup}
              width={'30%'}
              close={() => setNewOptionPopup(false)}
            >
              <div className={'popup-title'}>
                {t('newPropertyOption.title')}
              </div>
              {browsedGroupId ? (
                <NewPropertyOption
                  propertyGroup={browsedGroup}
                  newPosition={browsedGroup.options.length}
                  postSubmit={(option) => {
                    postNewOptionSubmission(option, productGroupMapping);
                    setNewOptionPopup(false);
                  }}
                  isVariantOption={isVariantOption}
                />
              ) : null}
            </Popup>
          </>
        );
      }
    }
  };

  const browsedGroup = availableProperties.find((g) => g.id === browsedGroupId);

  const browsedGroupOptions = browsedGroup?.options.filter((option) =>
    isOptionAllowedToShow(option)
  );

  if (availablePropertyGroups.length > 0) {
    return (
      <div className="propertyBrowser-wrapper">
        <div
          className={'propertyBrowser'}
          style={maxHeight ? { maxHeight: maxHeight } : undefined}
        >
          <div className={'propertyBrowser-groups'}>
            {searchableProperties ? (
              <div className="propertyBrowser-groups-search">
                <PropertiesSearch
                  cta={t('search')}
                  propertyGroups={availablePropertyGroups}
                  searchablePropertyOptions={searchableProperties}
                  selectedPropertyOptions={selectedPropertyOptions}
                  selectedPropertyGroups={selectedPropertyGroups}
                  updateSelectedProperties={updateSelectedPropertyOptions}
                  selectPropertyGroup={selectPropertyGroup}
                  invertedColor
                />
              </div>
            ) : null}
            <div className="propertyBrowser-containers">
              {renderAllContainers()}
            </div>
          </div>
          {optionsDisabled ? null : browsedGroup && browsedGroupOptions ? (
            <div className="propertyBrowser-values">
              {searchableProperties ? (
                <div className="propertyBrowser-values-search">
                  <PropertiesSearch
                    key={browsedGroup.id}
                    cta={t('search_values')}
                    propertyGroups={availablePropertyGroups}
                    searchablePropertyOptions={browsedGroupOptions.map(
                      (option) => ({
                        ...option,
                        groupName: browsedGroup.name,
                      })
                    )}
                    selectedPropertyOptions={selectedPropertyOptions}
                    updateSelectedProperties={updateSelectedPropertyOptions}
                    selectPropertyGroup={selectPropertyGroup}
                    selectedPropertyGroups={selectedPropertyGroups}
                    optionsOnly
                    invertedColor
                  />
                </div>
              ) : null}
              <div className="propertyBrowser-values-list">
                {renderProperties()}
              </div>
              {renderValueActions(
                availableProductGroupMappings?.find(
                  (m) => m.propertyGroupId === browsedGroupId
                )
              )}
            </div>
          ) : null}
        </div>
        <div className={'propertyBrowser-actions'}>
          <ToggleSwitch
            toggled={sortBySelect}
            toggle={() => setSortBySelect(!sortBySelect)}
            label={t('sortBySelected')}
            smallSwitch
            smallLabel
          />
          {refreshAvailablePropertyGroups ? (
            <Button
              type={'icon'}
              look={'secondary'}
              width={'tiny'}
              action={refreshAvailablePropertyGroups}
            >
              <IconReload
                className={'button-icon-tiny button-icon-secondary'}
              />
            </Button>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div className={'propertyBrowser'}>
        <IconProhibit className={'propertyBrowser-prohibited'} />
      </div>
    );
  }
};

export default PropertyBrowser;
