import './productvalidationlist.css';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import React, { useEffect, useState } from 'react';
import {
  ProductValidationResultResponse,
  ValidationFailureResponse,
} from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import ProductList from '../../features/productlist/ProductList';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/button/Button';
import Popup from '../../elements/popup/Popup';
import ErrorsOverview from './errorsoverview/ErrorsOverview';
import Badge from '../../elements/badge/Badge';
import useManufacturerOptions from '../../hooks/useManufacturerOptions';
import { DropdownOption } from '../../elements/selectors/Selectors';
import useListFilters from '../../hooks/list/useListFilters';
import { ListControlsFilter } from '../../features/list/listcontrols/ListControls';
import { ReactComponent as IconTrash } from '../../../assets/icon/trash.svg';
import ValidationsDeletion from './validationsdeletion/ValidationsDeletion';

const ProductValidationList: React.FC = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productValidation',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { manufacturerOptions } = useManufacturerOptions();

  const [mOpt, setMOpt] = useState<DropdownOption[] | null>(null);
  const { selectedManufacturerIds, multiManufacturerFilter } = useListFilters({
    presetManufacturerOptions: mOpt,
  });
  const [deletePopup, setDeletePopup] = useState(false);
  const [validationResults, setValidationResults] = useState<
    ProductValidationResultResponse[] | null
  >(null);
  const [validationResult, setValidationResult] =
    useState<ProductValidationResultResponse | null>(null);

  useEffect(() => {
    getValidationResults();
  }, []);

  useEffect(() => {
    if (validationResults) filterMOpt(validationResults);
  }, [validationResults]);

  const filterMOpt = (results: ProductValidationResultResponse[]) => {
    if (manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION') {
      const filteredOptions = manufacturerOptions.filter(
        (option) =>
          results.some((result) => result.manufacturerId === option.id) ||
          option.id === 'all'
      );
      setMOpt(filteredOptions);
    }
  };

  const getValidationResults = () => {
    productsApi
      .productsGetValidationResults()
      .then((response) => {
        console.log(response);
        setValidationResults(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const renderErrors = (
    errors: ValidationFailureResponse[] | null,
    result: ProductValidationResultResponse | null
  ) => {
    return (
      <div className={'productValidationList-errors'}>
        <div className={'productValidationList-errors-count'}>
          <Badge
            title={errors?.length.toString() ?? '0'}
            color={'var(--color-danger)'}
          />
        </div>
        <Button
          width={'tiny'}
          cta={t('errorsButton')}
          action={() => setValidationResult(result)}
          active={!!result}
          look={'secondary'}
        />
      </div>
    );
  };

  const getFilteredResults = (arr: ProductValidationResultResponse[]) => {
    let results = arr;
    if (
      selectedManufacturerIds &&
      selectedManufacturerIds.length > 0 &&
      !selectedManufacturerIds.includes('all') &&
      results
    ) {
      results = results.filter((result) =>
        selectedManufacturerIds.includes(result.manufacturerId)
      );
    }
    return results;
  };

  const getListConstrolsFilters = () => {
    const filters: ListControlsFilter[] = [];
    if (multiManufacturerFilter) {
      filters.push(multiManufacturerFilter);
    }
    filters.push({
      renderMethod: () => {
        return (
          <Button
            type={'icon'}
            width={'minimal'}
            action={() => setDeletePopup(true)}
            look={'secondary'}
          >
            <IconTrash className={'button-icon-tiny button-icon-danger'} />
          </Button>
        );
      },
    });
    return filters;
  };

  if (validationResults) {
    return (
      <div className={'productValidationList'}>
        <ProductList
          prefetchedProducts={getFilteredResults(validationResults)}
          renderObjects={[
            {
              key: 'errors',
              renderMethod: renderErrors,
            },
          ]}
          allowActions
          adjustHeightToViewportOffset={120}
          listConstrolsFilters={getListConstrolsFilters()}
        />
        {validationResult && validationResult.errors ? (
          <Popup
            toggled={true}
            width={'40%'}
            close={() => setValidationResult(null)}
          >
            <div className={'popup-title'}>{t('popup.title')}</div>
            <ErrorsOverview
              errors={validationResult.errors}
              productId={validationResult.id}
              isVariant={!!validationResult.parentId}
              showMessageInHead
            />
          </Popup>
        ) : null}
        <ValidationsDeletion
          isPopupOpen={deletePopup}
          setPopupOpen={setDeletePopup}
          manufacturerOptions={mOpt}
          onDeleteSuccess={getValidationResults}
        />
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductValidationList;
