import './productproperties.css';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../api/PetCloudApi';
import {
  AnimalSpeciesResponse,
  CreatePropertyGroupRequest,
  ProductGroupResponse,
  PropertyGroupResponse,
  UpdatePropertyGroupRequest,
} from '../../../api/petcloudapi/api';
import List from '../../../features/list/List';
import { LoadingContainer } from '../../../elements/loading/Loading';
import Popup from '../../../elements/popup/Popup';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../../elements/selectors/Selectors';
import { useErrorHandler } from '../../../contexts/errorhandler/ErrorHandler';
import TranslatedStringIndex from '../../../types/TranslatedStringIndex';
import Button from '../../../elements/button/Button';
import { arrayMoveImmutable } from 'array-move';
import { DragEndEvent } from '@dnd-kit/core';
import useNotifications from '../../../hooks/useNotifications';
import PropertyGroupEdit from './propertygroupedit/PropertyGroupEdit';
import NewPropertyGroup from './newpropertygroup/NewPropertyGroup';
import Badge from '../../../elements/badge/Badge';
import AnimalSpeciesEditor from '../animalspecies/animalspecieseditor/AnimalSpeciesEditor';
import AnimalSpeciesBulkUpdater from '../animalspecies/animalspeciesbulkupdater/AnimalSpeciesBulkUpdater';
import ToolsMenu from '../../../features/list/listcontrols/toolsmenu/ToolsMenu';
import useUserTools from '../../../hooks/useUserTools';
import useListRenderObjects from '../../../hooks/list/useListRenderObjects';
import useListControlsSearch from '../../../features/list/listcontrols/utils/useListControlsSearch';

const newPropertyGroup = {
  position: 0,
  name: {
    'de-DE': '',
    'en-GB': '',
  },
  description: {
    'de-DE': '',
    'en-GB': '',
  },
  identifier: '',
  productUnitId: null,
  isLanguageNeutral: false,
};

const ProductProperties = () => {
  const { t, i18n } = useTranslation('translations', {
    keyPrefix: 'view.admin.properties',
  });
  const { pushNotification } = useNotifications();
  const api = usePetCloudApi();
  const propertyGroupsApi = api.propertyGroupsApi();
  const animalSpeciesApi = api.animalSpeciesApi();
  const productUnitsApi = api.productUnitsApi();
  const errorHandler = useErrorHandler();
  const { excludeUnlockedOptions } = useUserTools();
  const { renderTranslatedStringInput, renderStringInput } =
    useListRenderObjects();
  const { listControlSearch, currentItems, setOriginalItems, originalItems } =
    useListControlsSearch(t('search'), null, [
      'identifier',
      'name.de-DE',
      'name.en-GB',
    ]);
  const [initialProductGroups, setInitialProductGroups] = useState<
    PropertyGroupResponse[] | null
  >(null);

  const [selectedLocale, setSelectedLocale] = useState<TranslatedStringIndex>(
    i18n.language as TranslatedStringIndex
  );
  const [selectedPropertyGroups, setSelectedPropertyGroups] = useState<
    PropertyGroupResponse[]
  >([]);
  const [propertyGroupToEdit, setPropertyGroupToEdit] =
    useState<PropertyGroupResponse | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupDangerPopup, setGroupDangerPopup] = useState(false);
  const [newPropertyGroupPopup, setNewPropertyGroupPopup] = useState(false);
  const [newPropertyGroupRequest, setNewPropertyGroupRequest] =
    useState<CreatePropertyGroupRequest>(newPropertyGroup);
  const [animalSpeciesArray, setAnimalSpeciesArray] = useState<number | null>(
    null
  );
  const [animalSpecies, setAnimalSpecies] = useState<
    AnimalSpeciesResponse[] | null
  >(null);
  const [productUnitOptions, setProductUnitOptions] = useState<
    DropdownOption[] | null
  >(null);
  const [speciesBulkEditor, setSpeciesBulkEditor] = useState(false);

  useEffect(() => {
    getAnimalSpecies();
    getProductUnits();
  }, []);

  const getProductUnits = () => {
    productUnitsApi
      .productUnitsGetProductUnits()
      .then((response) => {
        console.log(response);
        const options = response.data.map((x) => ({
          name:
            x.name[i18n.language as TranslatedStringIndex] ??
            'missing translation',
          id: x.id,
        }));
        options.unshift({
          name: t('noUnit'),
          id: 'null',
        });
        setProductUnitOptions(options);
        getProductProperties();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getAnimalSpecies = () => {
    animalSpeciesApi
      .animalSpeciesGetAnimalSpecies()
      .then((response) => {
        console.log(response);
        setAnimalSpecies(response.data);
        getProductProperties();
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const getProductProperties = () => {
    propertyGroupsApi
      .propertyGroupsGetPropertyGroups(
        undefined,
        undefined,
        excludeUnlockedOptions()
      )
      .then((response) => {
        console.log(response);
        const sorted = _.sortBy(response.data, ['position']);
        setOriginalItems([...sorted]);
        setInitialProductGroups([...sorted]);
        if (propertyGroupToEdit) {
          const updatedGroup = response.data.find(
            (g) => g.id === propertyGroupToEdit.id
          );
          if (updatedGroup) {
            setPropertyGroupToEdit({ ...updatedGroup });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  const submitUpdatedPropertyGroups = (
    updatedProductProperties: PropertyGroupResponse[]
  ) => {
    if (initialProductGroups) {
      const promises: Promise<unknown>[] = [];
      setIsSubmitting(true);
      updatedProductProperties.forEach((group) => {
        const ogGroup = initialProductGroups.find((g) => g.id === group.id);
        if (ogGroup) {
          if (JSON.stringify(group) !== JSON.stringify(ogGroup)) {
            promises.push(submitPropertyGroup(group));
          }
        }
      });

      Promise.all(promises)
        .then(() => {
          setIsSubmitting(false);
          setNewPropertyGroupPopup(false);
          pushNotification(t('notifications.updateGroups_successful'));
        })
        .catch(() => {
          setIsSubmitting(false);
          setNewPropertyGroupPopup(false);
          pushNotification(t('notifications.updateGroups_failed'), 'danger');
        });
    }
  };

  const submitPropertyGroup = (group: PropertyGroupResponse) => {
    return new Promise((resolve, reject) => {
      const request: UpdatePropertyGroupRequest = {
        ...group,
        animalSpeciesIds: group.animalSpecies?.map((x) => x.id),
      };
      propertyGroupsApi
        .propertyGroupsUpdatePropertyGroup(group.id, request)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const selectPropertyGroup = (item: PropertyGroupResponse) => {
    const update = selectedPropertyGroups;
    const i = update.findIndex((group) => group.id === item.id);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(item);
    }
    setSelectedPropertyGroups([...update]);
  };

  const selectAllPropertyGroups = () => {
    if (currentItems && currentItems.length !== selectedPropertyGroups.length) {
      setSelectedPropertyGroups([...currentItems]);
    } else {
      setSelectedPropertyGroups([]);
    }
  };

  const deleteSelectedPropertyGroups = () => {
    setIsSubmitting(true);
    const promises = selectedPropertyGroups.map((group) => {
      return deletePropertyGroup(group.id);
    });

    Promise.all(promises)
      .then(() => {
        pushNotification(t('notifications.deleteGroup_successful'));
        setGroupDangerPopup(false);
        setIsSubmitting(false);
        setSelectedPropertyGroups([]);
      })
      .catch(() => {
        pushNotification(t('notifications.deleteGroup_failed'), 'danger');
        setGroupDangerPopup(false);
        setIsSubmitting(false);
        setSelectedPropertyGroups([]);
      });
  };

  const deletePropertyGroup = (id: string) => {
    return new Promise((resolve, reject) => {
      propertyGroupsApi
        .propertyGroupsDeletePropertyGroup(id)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject(error);
        });
    });
  };

  const submitNewPropertyGroup = () => {
    if (currentItems) {
      setIsSubmitting(true);
      const request = newPropertyGroupRequest;
      request.position = currentItems.length;
      propertyGroupsApi
        .propertyGroupsCreatePropertyGroup(request)
        .then((response) => {
          console.log(response);
          pushNotification(t('notifications.createGroup_successful'));
          getProductProperties();
          setIsSubmitting(false);
          setNewPropertyGroupRequest(newPropertyGroup);
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          setIsSubmitting(false);
        });
    }
  };

  const onGroupListRowDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (currentItems && over) {
      if (active.id !== over.id) {
        const oldPosition = currentItems.find(
          (group) => group.id === active.id
        )?.position;
        const newPosition = currentItems.find(
          (group) => group.id === over.id
        )?.position;
        if (oldPosition !== undefined && newPosition !== undefined) {
          updateProductPropertiesPosition(oldPosition, newPosition);
        }
      }
    }
  };

  const updateProductPropertiesPosition = (
    oldPosition: number,
    newPosition: number
  ) => {
    if (currentItems) {
      const updatedProductProperties = [
        ...arrayMoveImmutable(currentItems, oldPosition, newPosition),
      ];
      reIndexAndUpdateProperties(updatedProductProperties);
    }
  };

  const reIndexAndUpdateProperties = (
    productPropertiesArray: PropertyGroupResponse[]
  ) => {
    const reIndexed = productPropertiesArray.map((group, i) => {
      return {
        ...group,
        position: i,
      };
    });
    setOriginalItems([...reIndexed]);
  };

  const renderProductUnitId = (
    id: any,
    item: ProductGroupResponse,
    index: number,
    options: DropdownOption[]
  ) => {
    return (
      <Dropdown
        optionObjects={options}
        selected={options.find((o) => o.id === id)?.name ?? options[0].name}
        update={(e) => {
          if (currentItems) {
            const update = [...currentItems];
            const id =
              e.currentTarget.selectedOptions[0].getAttribute('data-value');
            update[index] = {
              ...update[index],
              productUnitId: id !== 'null' ? id : null,
            };
            setOriginalItems(update);
          }
        }}
        widthAuto
      />
    );
  };

  const renderCheckBox = (value: boolean, item: any, index: number) => {
    return (
      <Check
        checked={value}
        update={() => {
          if (currentItems) {
            const update = [...currentItems];
            update[index] = {
              ...update[index],
              isLanguageNeutral: !update[index].isLanguageNeutral,
            };
            setOriginalItems(update);
          }
        }}
      />
    );
  };

  if (currentItems && animalSpecies && productUnitOptions) {
    return (
      <div className="productProperties">
        <List
          key={selectedLocale + isSubmitting}
          items={currentItems}
          ignore={[
            'id',
            'position',
            'updatedAt',
            'shopReferenceId',
            'syncedAt',
            'productUnit',
          ]}
          sortValueFunctions={{
            name: (group) =>
              group.name[i18n.language as TranslatedStringIndex].toLowerCase(),
            description: (group) =>
              group.description[
                i18n.language as TranslatedStringIndex
              ].toLowerCase(),
          }}
          dateStrings={['syncedAt', 'createdAt']}
          onRowClick={(item: any) => {
            const pro = currentItems?.find((p) => p.id === item.id) ?? null;
            console.log(pro);
            setPropertyGroupToEdit(
              currentItems?.find((p) => p.id === item.id) ?? null
            );
          }}
          actions={[
            {
              cta: 'edit',
              look: 'blue',
              action: (item: any) => {
                setPropertyGroupToEdit(
                  currentItems?.find((p) => p.id === item.id) ?? null
                );
              },
            },
          ]}
          selectedLocale={selectedLocale}
          selectedItems={selectedPropertyGroups}
          onSelect={selectPropertyGroup}
          onSelectAll={selectAllPropertyGroups}
          onDragEnd={onGroupListRowDragEnd}
          renderObjects={[
            {
              key: 'name',
              renderMethod: (value, item) => {
                return renderTranslatedStringInput(
                  value,
                  selectedLocale,
                  originalItems,
                  setOriginalItems,
                  item,
                  'name',
                  'productProperties-input'
                );
              },
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'description',
              renderMethod: (value, item) => {
                return renderTranslatedStringInput(
                  value,
                  selectedLocale,
                  originalItems,
                  setOriginalItems,
                  item,
                  'description',
                  'productProperties-input'
                );
              },
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'identifier',
              renderMethod: (value, item) =>
                renderStringInput(
                  value,
                  originalItems,
                  setOriginalItems,
                  item,
                  'identifier',
                  'productProperties-input'
                ),
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'animalSpecies',
              renderMethod: (value, item, index) =>
                renderAnimalSpecies(
                  value,
                  item,
                  index,
                  (index) => setAnimalSpeciesArray(index),
                  selectedLocale
                ),
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'productUnitId',
              renderMethod: (id, item, index) =>
                renderProductUnitId(id, item, index, productUnitOptions),
              stopPropagation: true,
              receiveNullValues: true,
            },
            {
              key: 'isLanguageNeutral',
              renderMethod: renderCheckBox,
              receiveNullValues: true,
              stopPropagation: true,
            },
          ]}
          listControls={{
            search: listControlSearch,
            children: (
              <>
                <div className={'listControls-filter'}>
                  <Dropdown
                    options={['de-DE', 'en-GB']}
                    selected={selectedLocale}
                    update={(e) => {
                      const val =
                        e.target.selectedOptions[0].getAttribute('data-value');
                      if (val) {
                        setSelectedLocale(val as TranslatedStringIndex);
                      }
                    }}
                  />
                </div>
                <ToolsMenu
                  active={selectedPropertyGroups.length > 0}
                  actions={[
                    {
                      cta: t('actions.bulkUpdateSpecies'),
                      ctaAlreadyTranslated: true,
                      look: 'blue',
                      action: () => setSpeciesBulkEditor(true),
                    },
                    {
                      cta: t('actions.delete'),
                      ctaAlreadyTranslated: true,
                      look: 'danger',
                      action: () => setGroupDangerPopup(true),
                    },
                  ]}
                />
              </>
            ),
            rightAlignedChildren: (
              <Button
                cta={t('actions.save')}
                look="save"
                isLoading={isSubmitting}
                action={() => submitUpdatedPropertyGroups(currentItems)}
                width="minimal"
                active={originalItems !== initialProductGroups}
              />
            ),
          }}
          adjustHeightToViewport
          adjustHeightToViewportOffset={120}
          tableHeadContrast
          isShowingIndex
        />
        <div className="global-cardActions-postBorder">
          <Button
            cta={t('actions.new')}
            width={'minimal'}
            look={'secondary'}
            action={() => setNewPropertyGroupPopup(true)}
          />
        </div>
        <Popup
          width="50%"
          toggled={newPropertyGroupPopup}
          close={() => setNewPropertyGroupPopup(false)}
        >
          <div className="popup-title">{t('newGroupPopup.title')}</div>
          <NewPropertyGroup
            group={newPropertyGroupRequest}
            setGroup={setNewPropertyGroupRequest}
            availableAnimalSpecies={animalSpecies}
          />
          <div className="global-cardActions productProperties-actions">
            <Button
              cta={t('newGroupPopup.submit')}
              width={'minimal'}
              look="save"
              action={() => {
                submitNewPropertyGroup();
                setNewPropertyGroupPopup(false);
              }}
              isLoading={isSubmitting}
            />
          </div>
        </Popup>
        {propertyGroupToEdit ? (
          <Popup
            width="60%"
            toggled={true}
            close={() => {
              setPropertyGroupToEdit(null);
            }}
          >
            <div className="popup-title">{`${t('groupPopup.title')}: ${
              propertyGroupToEdit.name[i18n.language as TranslatedStringIndex]
            }`}</div>
            <PropertyGroupEdit
              key={JSON.stringify(propertyGroupToEdit)}
              propertyGroup={propertyGroupToEdit}
              getProductProperties={() => getProductProperties()}
              availableAnimalSpecies={animalSpecies}
              selectedLocale={selectedLocale}
            />
          </Popup>
        ) : null}
        <Popup
          width="30%"
          toggled={groupDangerPopup}
          close={() => setGroupDangerPopup(false)}
        >
          <div className="popup-title">{t('groupDangerPopup.title')}</div>
          <div className="productproperties-dangerPopup-message">
            {t('groupDangerPopup.message')}
          </div>
          <Button
            width="full"
            look="danger"
            cta={t('groupDangerPopup.cta')}
            action={deleteSelectedPropertyGroups}
            isLoading={isSubmitting}
          />
        </Popup>
        {animalSpeciesArray !== null ? (
          <Popup
            toggled={true}
            width={'30%'}
            close={() => setAnimalSpeciesArray(null)}
          >
            <div className={'popup-title'}>{t('animalSpeciesPopup.title')}</div>
            <AnimalSpeciesEditor
              availableAnimalSpecies={animalSpecies}
              animalSpecies={currentItems[animalSpeciesArray].animalSpecies}
              onDelete={(index) => {
                console.log(index);
                const update = [...currentItems];
                const species = update[animalSpeciesArray].animalSpecies;
                if (species) {
                  let updatedSpecies: AnimalSpeciesResponse[] = [...species];
                  updatedSpecies.splice(index, 1);
                  update[animalSpeciesArray] = {
                    ...update[animalSpeciesArray],
                    animalSpecies: updatedSpecies,
                  };
                  setOriginalItems(update);
                }
              }}
              onSubmit={(selectedAnimalSpecies) => {
                const update = [...currentItems];
                const currentSpecies = update[animalSpeciesArray].animalSpecies;
                let updatedSpecies: AnimalSpeciesResponse[] = [];
                if (currentSpecies) {
                  updatedSpecies = [...currentSpecies];
                }
                updatedSpecies.push(selectedAnimalSpecies);
                update[animalSpeciesArray] = {
                  ...update[animalSpeciesArray],
                  animalSpecies: updatedSpecies,
                };
                setOriginalItems(update);
              }}
            />
            <div className={'global-cardActions'}>
              <Button
                cta={t('animalSpeciesPopup.cta')}
                look={'secondary'}
                width={'minimal'}
                action={() => setAnimalSpeciesArray(null)}
              />
            </div>
          </Popup>
        ) : null}
        <Popup
          toggled={speciesBulkEditor}
          width={'30%'}
          close={() => setSpeciesBulkEditor(false)}
        >
          <AnimalSpeciesBulkUpdater
            availableAnimalSpecies={animalSpecies}
            onSubmit={(species) => {
              const update = [...currentItems];
              selectedPropertyGroups.forEach((group) => {
                const i = update.findIndex((g) => g.id === group.id);
                if (i !== -1) {
                  update[i] = {
                    ...update[i],
                    animalSpecies: species,
                  };
                }
              });
              setOriginalItems(update);
            }}
          />
        </Popup>
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ProductProperties;

export const renderAnimalSpecies = (
  animalSpecies: AnimalSpeciesResponse[] | null | undefined,
  item: ProductGroupResponse,
  index: number,
  onClick: (index: number) => void,
  selectedLocale: TranslatedStringIndex
) => {
  return (
    <div
      className={'input-field input-field-hoverable'}
      onClick={() => {
        console.log(index);
        onClick(index);
      }}
    >
      {animalSpecies?.map((s) => {
        return (
          <div className={'productProperties-animalSpeciesBadge'}>
            <Badge
              title={s.name[selectedLocale] ?? 'missing translation'}
              color={'var(--color-text_secondary)'}
              hoverable
            />
          </div>
        );
      })}
    </div>
  );
};
