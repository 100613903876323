import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListControlsFilter } from '../../features/list/listcontrols/ListControls';
import useUrlParams from '../../hooks/useUrlParams';
import useDateTools from '../../hooks/useDateTools';
import useManufacturerOptions from '../useManufacturerOptions';
import { DropdownOption } from '../../elements/selectors/Selectors';

type ListFiltersOptions = {
  defaultLimit?: number;
  showTimeSelect?: boolean;
  noAllManufacturers?: boolean;
  presetManufacturerOptions?: DropdownOption[] | null;
};

const useListFilters = (opts?: ListFiltersOptions) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'components.useListFilters',
  });
  const { setUrlParam, removeUrlParam, getUrlParam } = useUrlParams();
  const { getDateOnlyFromDate } = useDateTools();
  const { manufacturerOptions } = useManufacturerOptions({
    noAllManufacturers: opts?.noAllManufacturers,
    presetManufacturerOptions: opts?.presetManufacturerOptions,
  });

  const getLimitFromURLParams = () => {
    const l = getUrlParam('limit');
    if (l) {
      if (l !== 'none') {
        return parseInt(l);
      } else {
        return undefined;
      }
    }
  };

  const [selectedLimit, setSelectedLimit] = useState<number | undefined>(
    getLimitFromURLParams() ??
      (opts && opts.defaultLimit ? opts.defaultLimit : 25)
  );

  const getDateRangeFromURLParams = () => {
    const dateRangeParam = getUrlParam('dateRange');
    if (dateRangeParam && dateRangeParam !== '') {
      return dateRangeParam.split(',');
    } else {
      return null;
    }
  };

  const [selectedDateRange, setSelectedDateRange] = useState<string[] | null>(
    getDateRangeFromURLParams()
  );

  const getManufacturerFromURLParams = () => {
    const manufacturerParam = getUrlParam('manufacturerId');
    if (manufacturerParam && manufacturerParam !== '') {
      return manufacturerParam;
    } else {
      return 'all';
    }
  };

  const getManufacturersFromURLParams = () => {
    const manufacturersParam = getUrlParam('manufacturerIds');
    if (manufacturersParam && manufacturersParam !== '') {
      const result = manufacturersParam.split('_');
      result.pop();
      return result;
    } else {
      return ['all'];
    }
  };

  const updateDateRange = (range: string[]) => {
    setSelectedDateRange(range);
    console.log(range.toString());
    setUrlParam('dateRange', range.toString());
  };

  const dateRangeOptions = [
    {
      name: t('dateRangeOptions.allTime'),
      id: 'allTime',
    },
    { name: t('dateRangeOptions.dateRange'), id: 'dateRange' },
  ];

  const dateRangeOptionFilter: ListControlsFilter = {
    optionObjects: dateRangeOptions,
    update: (option) => {
      const mode = option.id;
      if (mode) {
        if (mode === 'allTime') {
          setSelectedDateRange(null);
          removeUrlParam('dateRange');
        } else {
          const from = getDateOnlyFromDate(new Date());
          const to = getDateOnlyFromDate(new Date());
          if (from && to) setSelectedDateRange([from, to]);
        }
      }
    },
    nativeDropdown: true,
    selected: selectedDateRange
      ? dateRangeOptions[1].name
      : dateRangeOptions[0].name,
  };

  const dateRangeFilter: ListControlsFilter | undefined = selectedDateRange
    ? {
        dateRange: selectedDateRange,
        update: updateDateRange,
        showTimeSelect: (opts && opts.showTimeSelect) ?? false,
      }
    : undefined;

  const limitFilter: ListControlsFilter = {
    optionObjects: [
      { name: '25', id: '25' },
      {
        name: '50',
        id: '50',
      },
      { name: '100', id: '100' },
      { name: '500', id: '500' },
      { name: '1000', id: '1000' },
      { name: '2000', id: '2000' },
      {
        name: t('noLimit'),
        id: 'noLimit',
      },
    ],
    selected: selectedLimit ? selectedLimit.toString() : t('noLimit'),
    update: (option) => {
      const l = option.id;
      if (l && l !== 'noLimit' && selectedLimit !== parseInt(l)) {
        setSelectedLimit(parseInt(l));
        setUrlParam('limit', l);
      } else {
        setSelectedLimit(undefined);
        setUrlParam('limit', 'none');
      }
    },
    disableAutoOptionSorting: true,
    nativeDropdown: true,
  };

  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | undefined
  >(getManufacturerFromURLParams());

  const getManufacturerOptions = () => {
    return (
      opts?.presetManufacturerOptions ??
      (manufacturerOptions !== 'NO_PERMISSION'
        ? manufacturerOptions
        : undefined)
    );
  };

  const manufacturerFilter: ListControlsFilter | undefined = useMemo(() => {
    const options = getManufacturerOptions();

    const selected = opts?.presetManufacturerOptions
      ? opts?.presetManufacturerOptions.find(
          (x) => selectedManufacturerId === x.id
        )?.name
      : manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION'
      ? manufacturerOptions.find((x) => selectedManufacturerId === x.id)?.name
      : [];

    return options
      ? {
          optionObjects: options,
          selected: selected,
          update: (option) => {
            setSelectedManufacturerId(option.id);
            if (option.id !== 'all') {
              setUrlParam('manufacturerId', option.id);
            } else {
              removeUrlParam('manufacturerId');
            }
          },
        }
      : undefined;
  }, [manufacturerOptions, selectedManufacturerId]);

  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState<
    string[] | undefined
  >(getManufacturersFromURLParams());

  const multiManufacturerFilter: ListControlsFilter | undefined =
    useMemo(() => {
      const options = getManufacturerOptions();

      const selected = opts?.presetManufacturerOptions
        ? opts?.presetManufacturerOptions
            .filter((x) => selectedManufacturerIds?.includes(x.id))
            .map((x) => x.name)
        : manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION'
        ? manufacturerOptions
            .filter((x) => selectedManufacturerIds?.includes(x.id))
            .map((x) => x.name)
        : [];

      return options
        ? {
            optionObjects: options,
            multiSelected: selected,
            update: (option) => {
              console.log(option);
              const update = selectedManufacturerIds
                ? [...selectedManufacturerIds]
                : [];
              const i = update.findIndex((x) => x === option.id);
              if (i > -1) {
                update.splice(i, 1);
              } else {
                update.push(option.id);
              }
              setSelectedManufacturerIds(update);
              setUrlParam('manufacturerIds', update.join('_') + '_');
              if (option.id === 'all') {
                removeUrlParam('manufacturerIds');
              }
            },
          }
        : undefined;
    }, [manufacturerOptions, selectedManufacturerIds]);

  return {
    selectedLimit,
    selectedDateRange,
    selectedManufacturerId,
    selectedManufacturerIds,
    dateRangeOptionFilter,
    dateRangeFilter,
    limitFilter,
    manufacturerFilter,
    multiManufacturerFilter,
  };
};

export default useListFilters;
